import Glide from '@glidejs/glide';
import { autoBullets } from './glide.helpers.js';

new Glide('.glide', {
    type: 'carousel',
    startAt: 0,
    perView: 1,
    autoplay: 5000,
}).mount({
    AutoBullets: autoBullets,
});
